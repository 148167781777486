import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';

export enum UserPermissions {
  STAFF = 'staff',
}

export enum UserRole {
  USER = 'user',
  VALET = 'valet',
  ADMIN = 'admin',
  VALET_SUPERVISOR = 'valetSupervisor',
  KIOSK = 'kiosk',
  CONTROLLER = 'controller',
  CASHIER = 'cashier',
  AUDITOR = 'auditor',
  PARTNER = 'partner',
  PHOTO_MOMENTS_STAFF = 'momentStaff',
  RESTAURANT_PARTNER = 'restaurantPartner',
  RESTAURANT_PARTNER_STAFF = 'restaurantPartnerStaff',
  RESTAURANT_PARTNER_MOD = 'restaurantPartnerMod',
  CAMPUS_STAFF = 'campusStaff',
  PHOTO_MOMENTS_PARTNER = 'photoMomentsPartner',
  MOMENT_STAFF_PARTNER = 'momentStaffPartner',
  BILL_UPLOAD_REVIEWER = 'billUploadReviewer',
  LENSELY_PHOTOGRAPHER = 'lenslyPhotographer',
  MOMENT_PRINT_PARTNER ='momentPrintPartner',
}

export enum ControllerRole {
  PARK = 'PARK',
  RECALL = 'RECALL',
}

interface walletObjType {
  currentBalance: number;
  moneySaved: number;
}

export interface UserStoreInterface {
  firstName: string;
  lastName: string;
  primaryRole: UserRole | '';
  secondaryRole: UserRole[] | null;
  role: UserRole | '';
  permission: UserPermissions | null;
  id: string;
  contact: object;
  controllerRole: ControllerRole | null;
  profileImage: string | null;
  blackMembership: {
    id: string;
    active: boolean;
  };
  setUserDetails: (
    firstName: string,
    lastName: string,
    role: UserRole | '',
    permission: UserPermissions | null,
    id: string,
    contact?: object | null,
    primaryRole?: UserRole | '',
    secondaryRole?: [] | null,
    profileImage?: string | null,
  ) => void;
  investmentVideoPlayed: boolean;
  investmentPaymentReceived: boolean;
  investmentWidgetCount: number;
  checkHomePageInvestmentLead: boolean;
  freePhotosCount: number;
  totalFreePhotos: number;
  emptyUserDetails: () => void;
  setControllerRole: (role: ControllerRole | null) => void;
  setRole: (role: UserRole | '' | string) => void;
  setPrimaryRole: (primaryRole: UserRole) => void;
  setSecondaryRole: (secondaryRole: UserRole[]) => void;
  setProfileImage: (image: string) => void;
  wallet: walletObjType;
  setWalletBalance: ({ currentBalance, moneySaved }: walletObjType) => void;
  setBlackMembership: (mId: string, active: boolean) => void;
  setInvestmentVideoPlayed: (investmentVideoPlayed: boolean) => void;
  setInvestmentPaymentReceived: (investmentPaymentReceived: boolean) => void;
  setInvestmentWidgetCount: (investmentWidgetCount: number) => void;
  setFreePhotosCount: (freePhotosCount: number) => void;
  setTotalFreePhotos: (totalFreePhotos: number) => void;
  setCheckHomePageInvestmentLead: (
    checkHomePageInvestmentLead: boolean,
  ) => void;
}

const useUserStore = create<UserStoreInterface>()(
  devtools(
    persist(
      (set) => ({
        firstName: '',
        lastName: '',
        primaryRole: '',
        secondaryRole: [],
        role: '',
        permission: null,
        id: '',
        contact: {},
        controllerRole: null,
        profileImage: '',
        wallet: {
          currentBalance: null,
          moneySaved: null,
        },
        blackMembership: {
          id: '',
          active: false,
        },
        investmentVideoPlayed: false,
        investmentPaymentReceived: false,
        investmentWidgetCount: 0,
        checkHomePageInvestmentLead: false,
        freePhotosCount: 0,
        totalFreePhotos: 0,
        setUserDetails: (
          firstName: string,
          lastName: string,
          role: UserRole,
          permission: UserPermissions | null,
          id: string,
          contact: object = null,
          primaryRole: UserRole,
          secondaryRole: UserRole[],
          profileImage: string | null,
        ) =>
          set(() => ({
            firstName,
            lastName,
            primaryRole,
            secondaryRole,
            role,
            permission,
            id,
            contact,
            profileImage,
          })),
        emptyUserDetails: () =>
          set(() => ({
            firstName: '',
            lastName: '',
            role: '',
            permission: null,
            primaryRole: '',
            secondaryRole: [],
            id: '',
            contact: null,
            profileImage: '',
            investmentVideoPlayed: false,
            investmentPaymentReceived: false,
            investmentWidgetCount: 0,
            freePhotosCount: 0,
            totalFreePhotos: 0,
            wallet: {
              currentBalance: null,
              moneySaved: null,
            },
          })),
        setRole: (role: UserRole) => {
          set(() => {
            return {
              role,
            };
          });
        },
        setPrimaryRole: (primaryRole: UserRole) => {
          set(() => {
            return {
              primaryRole,
            };
          });
        },
        setSecondaryRole: (secondaryRole: UserRole[]) => {
          set(() => {
            return {
              secondaryRole,
            };
          });
        },
        setControllerRole: (role) =>
          set(() => ({
            controllerRole: role,
          })),
        setProfileImage: (image: string) =>
          set(() => ({
            profileImage: image,
          })),
        setWalletBalance: ({ currentBalance, moneySaved }: walletObjType) =>
          set(() => ({
            wallet: { currentBalance, moneySaved },
          })),
        setBlackMembership: (mId: string, active: boolean) =>
          set(() => ({
            blackMembership: {
              id: mId ?? '',
              active,
            },
          })),
        setInvestmentVideoPlayed: (investmentVideoPlayed: boolean) =>
          set(() => ({
            investmentVideoPlayed,
          })),
        setInvestmentWidgetCount: (investmentWidgetCount: number) =>
          set(() => ({
            investmentWidgetCount,
          })),
        setInvestmentPaymentReceived: (investmentPaymentReceived: boolean) =>
          set(() => ({
            investmentPaymentReceived,
          })),
        setFreePhotosCount: (freePhotosCount: number) =>
          set(() => ({
            freePhotosCount,
          })),
        setTotalFreePhotos: (totalFreePhotos: number) =>
          set(() => ({
            totalFreePhotos,
          })),
        setCheckHomePageInvestmentLead: (
          checkHomePageInvestmentLead: boolean,
        ) =>
          set(() => ({
            checkHomePageInvestmentLead,
          })),
      }),
      // setUserDetails('', '', '', null, '', null, '', []);

      {
        name: 'UserStore',
        storage: createJSONStorage(() => AsyncStorage),
      },
    ),
    {
      name: 'UserStore',
    },
  ),
);

export default useUserStore;
