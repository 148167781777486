/**
 * @generated SignedSource<<7553be1f476bec146c152002ee6934e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumUserMembershipsStatus = "ACTIVE" | "EXPIRED" | "PAUSED" | "%future added value";
export type EnumaddonAdditionalCashbackRefType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "MOMENT_ORDER" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnumaddonAdditionalDiscountRefType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "MOMENT_ORDER" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnumaddonPriceDiscountBaseDiscountType = "PERCENTAGE" | "%future added value";
export type EnumaddonType = "a_32ND_BLACK_MEMBERSHIP" | "%future added value";
export type EnumconfigPayBillConfigTipStatusRefType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "MOMENT_ORDER" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnumcsrContributionContributionType = "FIXED" | "%future added value";
export type FilterFindManyaddonInput = {
  AND?: ReadonlyArray<FilterFindManyaddonInput> | null;
  OR?: ReadonlyArray<FilterFindManyaddonInput> | null;
  _id?: any | null;
  _operators?: FilterFindManyaddonOperatorsInput | null;
  additionalCashback?: ReadonlyArray<FilterFindManyaddonAdditionalCashbackInput | null> | null;
  additionalDiscount?: ReadonlyArray<FilterFindManyaddonAdditionalDiscountInput | null> | null;
  expiryDuration?: FilterFindManyaddonExpiryDurationInput | null;
  isMembershipType?: boolean | null;
  name?: string | null;
  price?: number | null;
  priceDiscount?: ReadonlyArray<FilterFindManyaddonPriceDiscountInput | null> | null;
  tier?: number | null;
  type?: EnumaddonType | null;
};
export type FilterFindManyaddonPriceDiscountInput = {
  _id?: any | null;
  baseDiscount?: FilterFindManyaddonPriceDiscountBaseDiscountInput | null;
};
export type FilterFindManyaddonPriceDiscountBaseDiscountInput = {
  _id?: any | null;
  type?: EnumaddonPriceDiscountBaseDiscountType | null;
  value?: number | null;
};
export type FilterFindManyaddonExpiryDurationInput = {
  _id?: any | null;
  days?: number | null;
};
export type FilterFindManyaddonAdditionalCashbackInput = {
  _id?: any | null;
  cashback?: FilterFindManyaddonPriceDiscountBaseDiscountInput | null;
  refId?: any | null;
  refType?: EnumaddonAdditionalCashbackRefType | null;
};
export type FilterFindManyaddonAdditionalDiscountInput = {
  _id?: any | null;
  discount?: FilterFindManyaddonPriceDiscountBaseDiscountInput | null;
  refType?: EnumaddonAdditionalDiscountRefType | null;
};
export type FilterFindManyaddonOperatorsInput = {
  _id?: FilterFindManyaddon_idOperatorsInput | null;
  tier?: FilterFindManyaddonTierOperatorsInput | null;
  type?: FilterFindManyaddonTypeOperatorsInput | null;
};
export type FilterFindManyaddonTypeOperatorsInput = {
  exists?: boolean | null;
  gt?: EnumaddonType | null;
  gte?: EnumaddonType | null;
  in?: ReadonlyArray<EnumaddonType | null> | null;
  lt?: EnumaddonType | null;
  lte?: EnumaddonType | null;
  ne?: EnumaddonType | null;
  nin?: ReadonlyArray<EnumaddonType | null> | null;
};
export type FilterFindManyaddonTierOperatorsInput = {
  exists?: boolean | null;
  gt?: number | null;
  gte?: number | null;
  in?: ReadonlyArray<number | null> | null;
  lt?: number | null;
  lte?: number | null;
  ne?: number | null;
  nin?: ReadonlyArray<number | null> | null;
};
export type FilterFindManyaddon_idOperatorsInput = {
  exists?: boolean | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
  nin?: ReadonlyArray<any | null> | null;
};
export type paymentAmountScreenQueryTipConfigQuery$variables = {
  addonsFilter?: FilterFindManyaddonInput | null;
  isLoggedIn: boolean;
  userId: any;
};
export type paymentAmountScreenQueryTipConfigQuery$data = {
  readonly findAddons: ReadonlyArray<{
    readonly _id: any;
    readonly additionalCashback: ReadonlyArray<{
      readonly cashback: {
        readonly type: EnumaddonPriceDiscountBaseDiscountType | null;
        readonly value: number | null;
      };
      readonly refType: EnumaddonAdditionalCashbackRefType;
    } | null> | null;
    readonly additionalDiscount: ReadonlyArray<{
      readonly discount: {
        readonly type: EnumaddonPriceDiscountBaseDiscountType | null;
        readonly value: number | null;
      };
      readonly refType: EnumaddonAdditionalDiscountRefType;
    } | null> | null;
    readonly expiryDuration: {
      readonly days: number;
    } | null;
    readonly name: string;
    readonly price: number;
    readonly type: EnumaddonType;
  }>;
  readonly findCharities: ReadonlyArray<{
    readonly _id: any;
    readonly contribution: {
      readonly type: EnumcsrContributionContributionType;
      readonly value: number;
    };
    readonly description: string | null;
    readonly finePrint: {
      readonly details: ReadonlyArray<string | null> | null;
      readonly summary: string | null;
    } | null;
    readonly isActive: boolean | null;
    readonly title: string;
  }>;
  readonly findConfig: {
    readonly payBillConfig: {
      readonly tipStatus: ReadonlyArray<{
        readonly isTipEnabled: boolean | null;
        readonly refType: EnumconfigPayBillConfigTipStatusRefType;
      } | null> | null;
    } | null;
  } | null;
  readonly findUserById?: {
    readonly memberships: ReadonlyArray<{
      readonly expiryDate: any;
      readonly membershipAddonId: any;
      readonly status: EnumUserMembershipsStatus | null;
    } | null> | null;
  } | null;
};
export type paymentAmountScreenQueryTipConfigQuery = {
  response: paymentAmountScreenQueryTipConfigQuery$data;
  variables: paymentAmountScreenQueryTipConfigQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addonsFilter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isLoggedIn"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v3 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {}
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v8 = [
  (v7/*: any*/),
  (v6/*: any*/)
],
v9 = [
  {
    "alias": null,
    "args": (v3/*: any*/),
    "concreteType": "config",
    "kind": "LinkedField",
    "name": "findConfig",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "configPayBillConfig",
        "kind": "LinkedField",
        "name": "payBillConfig",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "configPayBillConfigTipStatus",
            "kind": "LinkedField",
            "name": "tipStatus",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isTipEnabled",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "findConfig(filter:{})"
  },
  {
    "alias": null,
    "args": (v3/*: any*/),
    "concreteType": "csrContribution",
    "kind": "LinkedField",
    "name": "findCharities",
    "plural": true,
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isActive",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TicketModelTCwoUserFinePrint",
        "kind": "LinkedField",
        "name": "finePrint",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "details",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "summary",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "csrContributionContribution",
        "kind": "LinkedField",
        "name": "contribution",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": "findCharities(filter:{})"
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "addonsFilter"
      }
    ],
    "concreteType": "addon",
    "kind": "LinkedField",
    "name": "findAddons",
    "plural": true,
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
      },
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "addonAdditionalCashback",
        "kind": "LinkedField",
        "name": "additionalCashback",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "addonPriceDiscountBaseDiscount",
            "kind": "LinkedField",
            "name": "cashback",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "addonAdditionalDiscount",
        "kind": "LinkedField",
        "name": "additionalDiscount",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "addonPriceDiscountBaseDiscount",
            "kind": "LinkedField",
            "name": "discount",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "addonExpiryDuration",
        "kind": "LinkedField",
        "name": "expiryDuration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "days",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "condition": "isLoggedIn",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "_id",
            "variableName": "userId"
          }
        ],
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "findUserById",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserMemberships",
            "kind": "LinkedField",
            "name": "memberships",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "membershipAddonId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expiryDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "paymentAmountScreenQueryTipConfigQuery",
    "selections": (v9/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "paymentAmountScreenQueryTipConfigQuery",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "e7146e09bbe4a24d79c4acaaf6f15650",
    "id": null,
    "metadata": {},
    "name": "paymentAmountScreenQueryTipConfigQuery",
    "operationKind": "query",
    "text": "query paymentAmountScreenQueryTipConfigQuery(\n  $addonsFilter: FilterFindManyaddonInput\n  $userId: MongoID!\n  $isLoggedIn: Boolean!\n) {\n  findConfig(filter: {}) {\n    payBillConfig {\n      tipStatus {\n        refType\n        isTipEnabled\n      }\n    }\n  }\n  findCharities(filter: {}) {\n    _id\n    title\n    description\n    isActive\n    finePrint {\n      details\n      summary\n    }\n    contribution {\n      type\n      value\n    }\n  }\n  findAddons(filter: $addonsFilter) {\n    _id\n    name\n    price\n    type\n    additionalCashback {\n      refType\n      cashback {\n        value\n        type\n      }\n    }\n    additionalDiscount {\n      refType\n      discount {\n        value\n        type\n      }\n    }\n    expiryDuration {\n      days\n    }\n  }\n  findUserById(_id: $userId) @include(if: $isLoggedIn) {\n    memberships {\n      membershipAddonId\n      status\n      expiryDate\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "11111c8dd78c2ba12d198a7c140170fb";

export default node;
