import React, { useCallback, useState } from 'react';
import { get } from 'lodash';
import styled from 'styled-components/native';
import View from '../../basic/View/View';
import Text from '../Typography/Text/Text';
import {
  InputLabelTextColorResolver,
  InputStates,
  InputHelperTextColorResolver,
} from './helpers';
import InputWithIcon from './InputWithIcon';
import { TextCategories } from '../Typography/Text/helper';

const InputLabelText = styled(Text)`
  color: ${({ variant, theme }) =>
    get(theme.colors, InputLabelTextColorResolver(variant))};
`;

const InputHelpetText = styled(Text)`
  color: ${({ variant, theme }) =>
    get(theme.colors, InputHelperTextColorResolver(variant))};
`;

const isMaxLengthEnabled = (props) =>
  typeof props?.maxLength === 'number' && props?.maxLength > -1;

function Input({
  state = InputStates.default,
  label = null,
  labelFontFamily = '',
  labelFontSize = '',
  helperText = '',
  successHelperText = '',
  errorHelperText = '',
  onFocus = () => {},
  onBlur = () => {},
  onChangeText,
  allowLeadingSpace = true,
  isRequired = false,
  ...props
}) {
  const [localState, setLocalInputState] = useState('');
  const [charRemaining, setCharRemaining] = useState(
    props?.maxLength ?? Number.MAX_SAFE_INTEGER,
  );

  const updateCharacterCount = useCallback(
    (value) => {
      if (value.length <= props?.maxLength) {
        setCharRemaining(Math.max(0, props?.maxLength - value.length));
      }
    },
    [props?.maxLength],
  );

  const handleTextChange = (val) => {
    let finalValue = val;
    if (allowLeadingSpace === false) {
      finalValue = val?.trimStart();
    }
    if (isMaxLengthEnabled(props)) {
      updateCharacterCount(finalValue);
    }
    onChangeText(finalValue);
  };

  return (
    <View flexDirection="column">
      {isMaxLengthEnabled(props) && (
        <View
          position="absolute"
          zIndex={10}
          right={0}
          bottom={0}
          pr="2xl"
          pb="lg"
        >
          <Text size="2xs" color="primary.100">
            {charRemaining}
          </Text>
        </View>
      )}
      {!!label && typeof label === 'string' && (
        <View mb="lg">
          <InputLabelText
            category={TextCategories.INPUTLABEL}
            variant={state}
            numberOfLines={1}
            ellipsizemode="tail"
            fontFamily={labelFontFamily || null}
            size={labelFontSize ?? null}
          >
            {label}
            {isRequired ? <Text color="error.500">*</Text> : <></>}
          </InputLabelText>
        </View>
      )}
      {!!label && typeof label !== 'string' && <View mb="lg">{label}</View>}
      <View>
        <InputWithIcon
          state={localState || state}
          onFocus={() => {
            if (state === InputStates.default || state === InputStates.error)
              setLocalInputState(InputStates.active);
            onFocus();
          }}
          onBlur={() => {
            setLocalInputState('');
            onBlur();
          }}
          onChangeText={handleTextChange}
          {...props}
        />
      </View>
      {/* helper text changes color automatically */}
      {!!helperText && (
        <View mt="sm">
          <InputHelpetText
            variant={state}
            category={TextCategories.HELPERTEXT}
            numberOfLines={2}
            ellipsizemode="tail"
          >
            {helperText}
          </InputHelpetText>
        </View>
      )}
      {!!errorHelperText && (
        <View mt="sm">
          <InputHelpetText
            variant={InputStates.error}
            category={TextCategories.HELPERTEXT}
            numberOfLines={2}
            ellipsizemode="tail"
          >
            {errorHelperText}
          </InputHelpetText>
        </View>
      )}
      {!!successHelperText && (
        <View mt="sm">
          <InputHelpetText
            variant={InputStates.success}
            category={TextCategories.HELPERTEXT}
            numberOfLines={2}
            ellipsizemode="tail"
          >
            {successHelperText}
          </InputHelpetText>
        </View>
      )}
    </View>
  );
}

export default Input;
