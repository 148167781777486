import React, { useContext, useState, useRef, useEffect } from 'react';
// import { Layout, View, Text, Icon, TopNavigation, IconButton, MobileInput, Button } from '';

import styled from 'styled-components/native';
import { StyleSheet, TextInput, useWindowDimensions } from 'react-native';
import Clipboard from '@react-native-community/clipboard';
import {
  color,
  border,
  space,
  shadow,
  position,
  variant as variantStyle,
} from 'styled-system';

import { max } from 'lodash';
import baseTheme from '../../../../themes/new/theme';
import { InputStates } from './helpers';
import View from '../../basic/View/View';

const variants = ({ theme }) => ({
  [InputStates.default]: {
    bg: 'primary.10',
    borderWidth: 'xs',
    color: 'primary.500',
    borderColor: 'primary.50',
    borderRadius: 'md',
  },
  [InputStates.success]: {
    bg: 'primary.10',
    borderWidth: 'xs',
    color: 'primary.500',
    borderColor: 'success.500',
    borderRadius: 'md',
  },
  [InputStates.error]: {
    bg: 'primary.10',
    borderWidth: 'xs',
    color: 'primary.500',
    // borderColor: 'error.500',
    borderColor: 'primary.50',
    borderRadius: 'md',
  },
  [InputStates.active]: {
    bg: 'primary.10',
    borderWidth: 'xs',
    color: 'primary.500',
    // border
    borderColor: 'primary.50',
    borderRadius: 'md',
    // shadows
    shadowColor: theme.colors.singletone.black,
    ...theme.boxShadows.md,
  },
  [InputStates.disabled]: {
    bg: 'primary.50',
    borderWidth: 'xs',
    color: 'primary.100',
    borderColor: 'primary.50',
    borderRadius: 'md',
  },
  [InputStates.unstyled]: {
    bg: 'primary.10',
    borderWidth: '0px',
    borderColor: 'primary.50',
  },
});

const StyledInputView = styled(View)(
  {},
  color,
  border,
  space,
  shadow,
  (props) => variantStyle({ variants: variants(props) }),
);

type OwnOtpInputSingleProps = {
  //   label: string;
  maxLength: number;
  refCallback?: (...args: any[]) => any;
  autoFocus?: boolean;
  [x: string]: any;
};

// @ts-expect-error TS(2456): Type alias 'OtpInputProps' circularly references i... Remove this comment to see the full error message
type OtpInputSingleProps = OwnOtpInputSingleProps &
  typeof OtpInputSingle.defaultProps;

const OtpInputSingle = (props: OtpInputSingleProps) => {
  //   const themeContext = useContext(ThemeContext);
  //   const keyboardTheme = themeContext.theme === 'light' ? 'light' : 'dark';
  const { maxLength, refCallback, autoFocus, ...restProps } = props;
  const { width, height } = useWindowDimensions();
  return (
    <StyledInputView
      // borderWidth={1}
      // borderColor="transparent"
      borderBottomWidth={2}
      borderBottomColor="primary.50"
      mr={width > 375 ? baseTheme.space['lg+9xl'] : '9xl'}
      // backgroundColor="red"
    >
      <StyledTextInput
        autoFocus={autoFocus}
        keyboardType="numeric"
        maxLength={maxLength}
        ref={refCallback}
        height={48}
        {...props}
      />
    </StyledInputView>
  );
};

OtpInputSingle.defaultProps = {
  refCallback: function name() {},
  autoFocus: undefined,
};

export const StyledTextInput = styled(TextInput)(
  // {
  //   width: 48,
  //   paddingBottom: 8,
  //   paddingTop: 16,
  //   textAlign: 'center',
  //   fontSize: 24,
  // },
  ({ theme }) => {
    return {
      fontSize: theme.fontSizes['2xl'],
      width: theme.space['9xl+lg'],
      paddingBottom: theme.space.lg,
      paddingTop: theme.space['2xl'],
      textAlign: 'center',
      color: theme.colors.primary['500'],
    };
  },
  color,
  border,
  space,
  shadow,
  (props) => variantStyle({ variants: variants(props) }),
);

export default OtpInputSingle;
